import { useEffect } from 'react';
import config from './cookieConsentConfig';
import 'vanilla-cookieconsent';
export default function CookieConsentComponent() {
  useEffect(() => {
    if (!document.getElementById('cc--main')) {
      window.CookieConsentApi = window.initCookieConsent();
      window.CookieConsentApi.run(config);
      document.body.classList.toggle('c_darkmode');
      document
        .querySelectorAll(`[role="heading"]`)
        .forEach((e) =>
          e.style.setProperty('font-family', 'GSKPrecision-Bold')
        );
      document
        .getElementById('cc_div')
        .style.setProperty('font-family', 'GSKPrecision-Regular');
      if (document.cookie.indexOf('cc_cookie') === -1) {
        document.getElementById('cs-ov').style.visibility = 'visible';
        document.getElementById('cs-ov').style.zIndex = '0';
        document.getElementById('cs-ov').style.opacity = '1';
      }
    }
  }, []);
  return null;
}
