import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import FirstGallery from './components/firstGallery/firstGallery';
import '../src/components/cookieConsent/cookieconsent.css';
import CookieConsentComponent from './components/cookieConsent/cookieConsentComponent';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <FirstGallery />
    <CookieConsentComponent />
  </React.StrictMode>
);
