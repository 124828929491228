const config = {
  gui_options: {
    consent_modal: {
      layout: 'cloud', // box/cloud/bar
      position: 'middle center', // bottom/middle/top + left/right/center
      transition: 'zoom', // zoom/slide
      swap_buttons: false, // enable to invert buttons
    },
    settings_modal: {
      layout: 'box', // box/bar
      // position: 'left',           // left/right
      transition: 'zoom', // zoom/slide
    },
  },
  current_lang: 'en',
  autoclear_cookies: true, // default: false
  page_scripts: true, // default: false
  test: 'ciao',
  // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
  // delay: 0,                               // default: 0
  // auto_language: null                     // default: null; could also be 'browser' or 'document'
  // autorun: true,                          // default: true
  // force_consent: false,                   // default: false
  // hide_from_bots: false,                  // default: false
  // remove_cookie_tables: false             // default: false
  // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
  cookie_expiration: 120, // default: 182 (days)
  // cookie_necessary_only_expiration: 182   // default: disabled
  // cookie_domain: location.hostname,       // default: current domain
  // cookie_path: '/',                       // default: root
  // cookie_same_site: 'Lax',                // default: 'Lax'
  // use_rfc_cookie: false,                  // default: false
  // revision: 0,                            // default: 0

  onFirstAction: function (user_preferences, cookie) {
    document.getElementById('cs-ov').style.visibility = 'hidden';
    document.getElementById('cs-ov').style.zIndex = '0';
    document.getElementById('cs-ov').style.opacity = '0';
  },

  onAccept: function (cookie) {
    document.getElementById('cs-ov').style.visibility = 'hidden';
    document.getElementById('cs-ov').style.zIndex = '0';
    document.getElementById('cs-ov').style.opacity = '0';
    // if (cookie.categories.find((c) => c === 'analytics')) {
    //   const script = document.createElement('script');
    //   script.id = 'cookieAnalyticScript';
    //   script.innerHTML = `(function (w, d, s, l, i) {
    //     w[l] = w[l] || [];
    //     w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    //     var f = d.getElementsByTagName(s)[0],
    //     j = d.createElement(s),
    //     dl = l != "dataLayer" ? "&l=" + l : "";
    //     j.async = true;
    //     j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    //     f.parentNode.insertBefore(j, f);
    //   })(window, document, "script", "dataLayer", "GTM-WGFJX2Z");`;
    //   document.getElementsByTagName('head')[0].appendChild(script);
    //   console.log('added in onAccept');
    // }
  },

  onChange: function (cookie, changed_preferences) {
    document.getElementById('cs-ov').style.visibility = 'hidden';
    document.getElementById('cs-ov').style.zIndex = '0';
    document.getElementById('cs-ov').style.opacity = '0';

    // if (
    //   cookie.categories.find((c) => c === 'analytics') &&
    //   !document.getElementById('cookieAnalyticScript')
    // ) {
    //   const script = document.createElement('script');
    //   script.id = 'cookieAnalyticScript';
    //   script.innerHTML = `(function (w, d, s, l, i) {
    //     w[l] = w[l] || [];
    //     w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    //     var f = d.getElementsByTagName(s)[0],
    //       j = d.createElement(s),
    //       dl = l != "dataLayer" ? "&l=" + l : "";
    //     j.async = true;
    //     j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    //     f.parentNode.insertBefore(j, f);
    //   })(window, document, "script", "dataLayer", "GTM-WGFJX2Z");`;
    //   document.getElementsByTagName('head')[0].appendChild(script);
    //   console.log('added in onChange');
    // } else if (
    //   changed_preferences.find((c) => c === 'analytics') &&
    //   !cookie.categories.find((c) => c === 'analytics')
    // )
    window.location.reload(true);
  },

  languages: {
    en: {
      consent_modal: {
        title: 'Wir verwenden Cookies!',
        description:
          'Wir verwenden Cookies, um Ihr Erlebnis mit unserer Webseite kontinuierlich zu verbessern. Selbstverständlich behandeln wir alle Daten streng vertraulich. <button type="button" data-cc="c-settings" class="cc-link">Individuelle Datenschutzeinstellungen</button>',
        primary_btn: {
          text: 'Alle akzeptieren',
          role: 'accept_all', // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: ' Alle ablehnen',
          role: 'accept_necessary', // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: 'Cookie-Einstellungen',
        save_settings_btn: 'Einstellungen speichern',
        accept_all_btn: 'Alle akzeptieren',
        close_btn_label: 'Close',
        cookie_table_headers: [
          { col1: 'Name' },
          { col2: 'Anbieter' },
          { col3: 'Zweck' },
          { col4: 'Datenschutzerklärung' },
          { col5: 'Cookie Name' },
          { col6: 'Cookie Laufzeit' },
        ],
        blocks: [
          {
            title: 'Individuelle Datenschutzeinstellungen',
            description:
              'Hier finden Sie eine Übersicht über alle verwendeten Cookies. Sie können Ihre Einwilligung zu ganzen Kategorien geben oder sich weitere Informationen anzeigen lassen.',
          },
          {
            title: 'Essenzielle Cookies',
            description:
              'Essenzielle Cookies ermöglichen grundlegende Funktionen und sind für die einwandfreie Funktion der Website erforderlich.',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
            },
            cookie_table: [
              {
                col1: 'Cookie Consent',
                col2: 'Eigentümer dieser Website',
                col3: 'Speichert die Einstellungen der Besucher, die in den Cookie-Einstellungen ausgewählt wurden.',
                col4: '<a class="cc-link" href="https://deine-guertelrose-erfahrung.de/teilnahmebedingungen-und-datenschutz">https://deine-guertelrose-erfahrung.de/teilnahmebedingungen-und-datenschutz</a>',
                col5: 'cc-cookie',
                col6: '4 Monate',
              },
            ],
          },
          // {
          //   title: 'Statistik Cookies',
          //   description:
          //     'Statistik Cookies erfassen Informationen anonym. Diese Informationen helfen uns zu verstehen, wie unsere Besucher unsere Website nutzen.',
          //   toggle: {
          //     value: 'analytics', // your cookie category
          //     enabled: false,
          //     readonly: false,
          //   },
          //   cookie_table: [
          //     // list of all expected cookies
          //     {
          //       col1: 'Google Tag Manager', // match all cookies starting with "_ga"
          //       col2: '	Google LLC',
          //       col3: 'Cookie von Google zur Steuerung der erweiterten Script- und Ereignisbehandlung.',
          //       col4: '<a class="cc-link" href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de></a>',
          //       col5: '_ga,_gat,_gid',
          //       col6: '2 Jahre',
          //     },
          //     {
          //       col1: 'Google Analytics',
          //       col2: 'Google LLC',
          //       col3: 'Cookie von Google für Website-Analysen. Erzeugt statistische Daten darüber, wie der Besucher die Website nutzt.',
          //       col4: '<a class="cc-link" href="https://policies.google.com/privacy">https://policies.google.com/privacy?hl=de></a>',
          //       col5: '_ga,_gat,_gid',
          //       col6: '2 Jahre',
          //     },
          //   ],
          // },
          {
            title: 'Mehr Informationen',
            description:
              'Sie finden mehr Informationen in unserem <a class="cc-link" href="https://de.gsk.com/de-de/impressum/">Impressum</a> und <a class="cc-link" href="https://deine-guertelrose-erfahrung.de/teilnahmebedingungen-und-datenschutz">Datenschutzbestimmungen</a>.',
          },
        ],
      },
    },
  },
};
export default config;
