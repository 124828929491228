import { Canvas, useLoader, useThree, useFrame } from '@react-three/fiber';
import React, { useEffect, useState } from 'react';
import {
  SpotLight,
  ScrollControls,
  Scroll,
  Html,
  useScroll,
} from '@react-three/drei';
import { EffectComposer, Vignette } from '@react-three/postprocessing';
import { TextureLoader } from 'three';
import './firstGallery.css';

const WallArts = (props) => {
  const data = useScroll();
  const [index, setIndex] = useState(0);
  useFrame(() => {
    // console.log(
    //   data.offset.toFixed(2) * 100,
    //   Math.floor(100 / props.images.length),
    //   Math.floor(
    //     (data.offset.toFixed(2) * 100) / (100 / props.images.length)
    //   )
    // );
    setIndex(
      Math.floor(
        (data.offset.toFixed(2) * 100) / (100 / props.images.length)
      )
    );
  });
  return (
    <>
      {props.images.map((art, i) => {
        return <WallArt key={i} i={i} art={art} index={index} />;
      })}
    </>
  );
};

const WallArt = (props) => {
  const { art, i, index } = props;
  const { height: h, width: w } = useThree((state) => state.viewport);
  // console.log(index, i);
  const gap = 4;
  const imageWidth = 3;
  const texture = useLoader(TextureLoader, art.imgPath);
  let spotlight = false;
  if (index >= i - 2 && index <= i + 2) {
    spotlight = true;
  }
  return (
    <>
      <group>
        {spotlight && (
          <SpotLight
            position={[(i + 1) * (imageWidth + gap) + w / 7, 2.5, 1]}
            penumbra={1}
            angle={0.9}
            attenuation={1}
            anglePower={5}
            intensity={5}
            distance={10}
            castShadow
            color={0xffffff}
          />
        )}
        <mesh
          castShadow
          position={[(i + 1) * (imageWidth + gap) + w / 7, 0, 0]}
        >
          <boxBufferGeometry
            attach="geometry"
            args={[
              (texture.image.width * (h / 2)) / texture.source.data.height,
              h / 2,
              0.07,
            ]}
          />
          <meshStandardMaterial
            attach="material"
            map={texture}
            roughness={1}
            metalness={0.5}
          />
        </mesh>

        <mesh position={[(i + 1) * (imageWidth + gap) + w / 7, -2.5, 0]}>
          <meshStandardMaterial color={0xfaebd7} />
          <Html wrapperClass="imageDetailsWrap" className="frame">
            <div className="imageDetailsText">
              <div>{art.artist}</div>
              <div style={{ fontFamily: 'GSKPrecision-Thin' }}>
                <i>{art.title}</i>
              </div>
            </div>
          </Html>
        </mesh>
      </group>
    </>
  );
};

const Scene = ({ images }) => {
  const { width: screenWidth } = useThree((state) => state.viewport);
  const widthPlusGap = 7;
  // console.log(
  //   `(screenWidth + (images.length + 1) * widthPlusGap)`,
  //   screenWidth + (images.length + 1) * widthPlusGap,
  //   `screenWidth`,
  //   screenWidth,
  //   `devide`,
  //   (screenWidth + (images.length + 1) * widthPlusGap) / screenWidth
  // );
  return (
    <>
      <Html
        wrapperClass="header"
        style={{
          position: 'initial',
          transform: 'initial',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <a href="https://de.gsk.com/de-de/" target="_blank" rel="noreferrer">
          <img src={require('../../images/gskLogo.png')} alt="somesay" />
        </a>
        <a href="https://galerie.deine-guertelrose-erfahrung.de/">
          <span className="loadingHeaderText">Deine Gürtelrose-Erfahrung</span>
        </a>
      </Html>

      <ScrollControls
        infinite
        horizontal
        damping={4}
        pages={(screenWidth + images.length * widthPlusGap) / screenWidth}
        distance={1}
      >
        <Scroll html style={{ right: '0px' }}>
          <div className="introductionContainer">
            <div
              className="row justify-content-center align-items-center m-0"
              style={{ width: '100vw', height: '100vh' }}
            >
              <div className="col">
                <div className="details">
                  <div className="title">Die Galerie</div>
                  <a
                    style={{ color: '#318CAD' }}
                    href="https://deine-guertelrose-erfahrung.de/#mitmachen"
                    target="_self"
                  >
                    Jetzt mitmachen{' '}
                  </a>
                  und zeigen, wie Sie die Erkrankung Gürtelrose erlebt haben!
                  <br />
                  Scrollen Sie, um die bisherigen Einreichungen anzusehen.
                </div>
                <div className="imgContainer">
                  <img
                    src={require('../../images/icon-scroll.png')}
                    alt="arrow"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="container-fluid">
              <div className="row justify-content-between">
                <div className="col-12 col-md-auto text-center mb-3 mb-md-0">
                  <a
                    href="https://deine-guertelrose-erfahrung.de"
                    target="_self"
                  >
                    <img
                      src={require('../../images/reverseArrow.png')}
                      alt="arrow"
                      style={{ cursor: 'pointer' }}
                    />
                    <span className="footer__leftText">
                      Zurück zur Webseite
                    </span>
                  </a>
                </div>
                <div className="col-12 col-md-auto text-center">
                  <a
                    href="https://de.gsk.com/de-de/impressum/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="p-2 p-md-4 footer__rightText">
                      Impressum
                    </span>
                  </a>
                  <a
                    href="https://deine-guertelrose-erfahrung.de/teilnahmebedingungen-und-datenschutz"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="footer__rightText">
                      Teilnahmebedingungen und Datenschutz
                    </span>
                  </a>

                  <span
                    className="footer__rightText"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      window.CookieConsentApi.showSettings(0);
                    }}
                  >
                    Cookie-Einstellungen
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Scroll>
        <Scroll>
          <WallArts images={images} />
        </Scroll>
      </ScrollControls>
    </>
  );
};

export default function FirstGallery() {
  const [images, setImages] = useState(null);
  const [introMsg, setIntroMsg] = useState('Galerie wird geladen...');
  useEffect(() => {
    async function fetchImages() {
      var myHeaders = new Headers();
      myHeaders.append(
        'Authorization',
        `Bearer ${process.env.REACT_APP_TOKEN}`
      );

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      fetch(
        `${process.env.REACT_APP_SERVER_ADDRESS}/api/artgalleries?populate=*&pagination[pageSize]=250`,
        requestOptions
      )
        .then((response) => {
          return response.text();
        })
        .then((res) => {
          const result = JSON.parse(res).data;
          let filteredResult = result.filter((img) => {
            if (process.env.REACT_APP_MODE === 'PRODUCTION')
              return img.attributes.production === true;
            return img.attributes.staging === true;
          });
          const tempArray = filteredResult.map((image) => ({
            imgPath: `${process.env.REACT_APP_SERVER_ADDRESS}${image.attributes.image.data.attributes.url}`,
            title: image.attributes.title,
            artist: image.attributes.artist,
          }));
          let shuffled = tempArray
            .map((value) => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value);
          setImages(shuffled);
          // setImages(tempArray);
        })
        .catch((err) => {
          setIntroMsg(
            `Unsere Galerie wird im Moment gewischt. \n
             Bitte versuchen Sie es später noch einmal.`
          );
          console.log('error', err);
        });
    }
    fetchImages();
  }, []);
  return (
    <Canvas
      camera
      gl={{
        capabilities: {
          maxFragmentUniforms: 10000,
        },
      }}
    >
      <ambientLight intensity={0.6} color={0xffffff} />

      <mesh position={[0, 0, -0.1]} receiveShadow>
        <planeGeometry args={[20, 15]} />
        <meshStandardMaterial color={0x000000} />
      </mesh>

      {!images ? (
        <>
          <Html
            // wrapperClass="header"
            center
            className="loadingGallery"
          >
            <div style={{ whiteSpace: 'pre-line', lineHeight: '27px' }}>
              {introMsg}
            </div>
          </Html>
          <Html
            wrapperClass="loadingGalleryHeader"
            style={{
              position: 'initial',
              transform: 'initial',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img src={require('../../images/gskLogo.png')} alt="somesay" />
            <span className="loadingHeaderText">
              Deine Gürtelrose-Erfahrung
            </span>
          </Html>
        </>
      ) : (
        <Scene images={images} />
      )}

      <EffectComposer>
        <Vignette eskil={false} offset={0.1} darkness={0.5} />
      </EffectComposer>
    </Canvas>
  );
}
